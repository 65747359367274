import { useContext, useEffect, useState } from "react";
import { Button, Form, FormCheck, FormControl, FormGroup, FormLabel } from "react-bootstrap";
import { ReactReduxContext, useDispatch } from "react-redux";
import { PARAMS_BOILER_TYPOLOGY, PARAMS_COST_KWH, PARAMS_COST_SMC, PARAMS_HEATING_TYPOLOGY, PARAMS_HOUSE_TYPOLOGY_COLD, PARAMS_HOUSE_TYPOLOGY_HOT, PARAMS_INSULATION_TYPOLOGY_COLD, PARAMS_INSULATION_TYPOLOGY_HOT, PARAMS_KWH_WEIGHT, PARAMS_KW_PHOTOVOLTAIC_COLD, PARAMS_KW_PHOTOVOLTAIC_HOT, PARAMS_MQ, PARAMS_MQ_MULTIPLIER, PARAMS_PHOTOVOLTAIC, PARAMS_SMC_WEIGHT } from "../constants/ParametriCalcolatore";
import { RESET_STEPS } from "../constants/ActionTypes";
import { useWizard } from "react-use-wizard";
import { useLocation } from "react-router-dom";
import { useLoader } from '../utils/hooks';
import axios from "axios";
import Swal from "sweetalert2";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import FormCheckLabel from "react-bootstrap/esm/FormCheckLabel";

function Step10() {
    const { store } = useContext(ReactReduxContext);
    const dispatch = useDispatch();
    const { goToStep } = useWizard();
    const location = useLocation();
    const [totaleRiscaldamento, setTotaleRiscaldamento] = useState(0);
    const [totaleRaffrescamento, setTotaleRaffrescamento] = useState(0);
    const [leadData, setLeadData] = useState({
        nome: null,
        cognome: null,
        email: null,
        cellulare: null,
        risposte: [],
        riscaldamento: 0,
        raffrescamento: 0,
        hasPompaCalore: false,
        isValutatoFotovoltaico: false,
        hasCaldaiaCondensazione: false,
        hasImpiantoRaffrescamento: false,
        isValutatoImpiantoRaffrescamento: false,
        hasRadiatori: false,
        hasPavimentoRiscaldato: false,
        priceForKwh: PARAMS_COST_KWH,
        priceForSmc: PARAMS_COST_SMC,
    });
    const loader = useLoader({ height: 546 });

    const calcola = () => {
        const data = { ...leadData };

        data.risposte = [];

        var totRiscaldamento = 1;
        var totRaffrescamento = 1;

        store.getState().steps.forEach(step => {
            if (step.nStep === 5) {
                var values = step.value.split("-");
                if (values[0] === "2") data.hasPompaCalore = true;
                else if (values[0] === "3" && values[1] === "1") data.hasRadiatori = true;
                else if (values[0] === "3" && values[1] === "2") data.hasPavimentoRiscaldato = true;
            }
            if (step.nStep === 3) {
                var values = step.value.split("-");
                if (values[0] === "0" && values[1] === "1") data.isValutatoFotovoltaico = true;
            }
            if (step.nStep === 7 && step.value === "2") data.hasCaldaiaCondensazione = true;
            if (step.nStep === 6) {
                var values = step.value.split("-");
                if (values[0] !== "3") data.hasImpiantoRaffrescamento = true;
                else if (values[1] === "1") data.isValutatoImpiantoRaffrescamento = true;
            }

            if (typeof step.parent !== "undefined") {
                var values = step.value.split("-");
                data.risposte.push({ 'domanda': step.parent.question, 'risposta': step.parent.answer, 'valore': values[0] });
            }
            data.risposte.push({ 'domanda': step.question, 'risposta': step.answer, 'valore': step.value });
        });

        store.getState().steps.forEach(step => {
            switch (step.nStep) {
                case 0:
                    totRiscaldamento *= PARAMS_MQ[step.value];
                    totRaffrescamento *= PARAMS_MQ[step.value] * PARAMS_MQ_MULTIPLIER[step.value];
                    break;
                case 1:
                    totRiscaldamento *= PARAMS_HOUSE_TYPOLOGY_HOT[step.value];
                    totRaffrescamento *= PARAMS_HOUSE_TYPOLOGY_COLD[step.value];
                    break;
                case 2:
                    totRiscaldamento *= PARAMS_INSULATION_TYPOLOGY_HOT[step.value];
                    totRaffrescamento *= PARAMS_INSULATION_TYPOLOGY_COLD[step.value];
                    break;
                case 3:
                    if (data.hasPompaCalore) {
                        var values = step.value.split("-");
                        totRiscaldamento *= PARAMS_PHOTOVOLTAIC[values[0]] * (PARAMS_COST_KWH * PARAMS_KWH_WEIGHT);
                    }
                    break;
                case 4:
                    totRiscaldamento *= PARAMS_KW_PHOTOVOLTAIC_HOT[step.value];
                    totRaffrescamento *= PARAMS_KW_PHOTOVOLTAIC_COLD[step.value];
                    break;
                case 5:
                    var values = step.value.split("-");
                    if (values.length > 1) totRiscaldamento *= PARAMS_HEATING_TYPOLOGY[values[0]][values[1]];
                    else totRiscaldamento *= PARAMS_HEATING_TYPOLOGY[values[0]];
                    break;
                case 7:
                    totRiscaldamento *= PARAMS_BOILER_TYPOLOGY[step.value];
                    break;
                default:
                    break;
            }
        });

        totRiscaldamento *= (PARAMS_COST_SMC / PARAMS_SMC_WEIGHT);
        totRaffrescamento *= (PARAMS_COST_KWH * PARAMS_KWH_WEIGHT);

        totRiscaldamento = Math.round((totRiscaldamento + Number.EPSILON) * 100) / 100;
        totRaffrescamento = Math.round((totRaffrescamento + Number.EPSILON) * 100) / 100;

        setTotaleRiscaldamento(totRiscaldamento);
        setTotaleRaffrescamento(totRaffrescamento);

        data.riscaldamento = totRiscaldamento;
        data.raffrescamento = totRaffrescamento;

        setLeadData(data);
    }

    const backToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour 
               in place of 'smooth' */
        });
    }

    useEffect(() => {
        if (location.pathname === '/') {
            dispatch({
                type: RESET_STEPS,
            });

            backToTop();

            goToStep(0);
        }
    }, [location]);

    useEffect(() => {
        loader.setStartLoading();
        loader.setEndLoading(3);
        calcola();
    }, []);

    const handleChange = (e) => {
        const data = { ...leadData };
        data[e.target.name] = e.target.value;
        setLeadData(data);
    };

    const insertLead = async (e) => {
        e.preventDefault();

        loader.watchPromise(
            axios
                .post(
                    '/estimator/insert/lead',
                    {
                        data: leadData
                    },
                    {
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                )
                .then((res) => {
                    if (res.data.status === 200) {
                        Swal
                            .fire({
                                title: `Controlla la tua casella email`,
                                html: `Ti abbiamo inviato la proposta per migliorare il tuo benessere abitativo e ridurre i consumi`,
                                icon: `success`,
                                confirmButtonText: `Chiudi`
                            })
                            .then(() => {
                                window.location.href = `/`;
                            });
                    } else {
                        Swal
                            .fire({
                                title: `Si è verificato un errore`,
                                html: res.data.errorText,
                                icon: `error`,
                                confirmButtonText: `Chiudi`
                            });
                    }
                })
                .catch((error) => {
                    Swal
                        .fire({
                            title: `Si è verificato un errore`,
                            html: `Qualcosa sembra non aver funzionato!`,
                            icon: `error`,
                            confirmButtonText: `Chiudi`
                        });
                })
        )
    }

    return (
        <>
            {loader.asJsx || (
                <>
                    <div style={{ maxWidth: 800 + 'px' }}>
                        <p className="mb-5 w-100 text-center fw-500">
                            Abbiamo stimato i costi che sostieni per il riscaldamento e il raffrescamento della tua casa e abbiamo una proposta per migliorare il tuo benessere abitativo e ridurre i consumi.<br /><br />
                            Lasciaci i tuoi dati e ti invieremo subito una proposta alla tua email.
                        </p>
                        <Form className="w-100" onSubmit={insertLead}>
                            <FormGroup className="w-100 mb-4">
                                <FormLabel className="fw-500">Nome</FormLabel>
                                <FormControl inputMode="text" name="nome" onChange={handleChange} required></FormControl>
                            </FormGroup>
                            <FormGroup className="w-100 mb-4">
                                <FormLabel className="fw-500">Cognome</FormLabel>
                                <FormControl inputMode="text" name="cognome" onChange={handleChange} required></FormControl>
                            </FormGroup>
                            <FormGroup className="w-100 mb-4">
                                <FormLabel className="fw-500">Email</FormLabel>
                                <FormControl inputMode="email" name="email" onChange={handleChange} required></FormControl>
                            </FormGroup>
                            <FormGroup className="w-100 mb-4">
                                <FormLabel className="fw-500">Cellulare</FormLabel>
                                <FormControl inputMode="tel" name="cellulare" onChange={handleChange} required></FormControl>
                            </FormGroup>
                            <FormCheck className="w-100 mb-4">
                                <FormCheckInput id="privacy" required></FormCheckInput>
                                <FormCheckLabel htmlFor="privacy">Ho letto e compreso <a href="https://www.iubenda.com/privacy-policy/42459401" target="_blank">l'Informativa sulla Privacy</a></FormCheckLabel>
                            </FormCheck>
                            <Button type="submit" className="btn-dark rounded mb-4 w-100 text-center">Inviami la proposta via email</Button>
                            <p className="w-100 text-center fw-500">
                                <small>I tuoi dati verranno usati solo per inviarti la proposta</small>
                            </p>
                        </Form>
                    </div>
                </>
            )}
        </>
    );
}

export default Step10;