import ButtonsStep from "./buttons-step";

import image1 from '../assets/sad.svg';
import image2 from '../assets/confused.svg';
import image3 from '../assets/happy.svg';

function Step3() {
    const stepTitle = `Come è isolata?`;

    const stepElements = [
        {
            "text": "Molto Bene.<br/>Classe energ.<br/>A o B",
            "image": image3,
            "value": 3
        },
        {
            "text": "Così Così.<br/>Classe energ.<br/>C o D",
            "image": image2,
            "value": 2
        },
        {
            "text": "Male.<br/>Classe energ.<br/>E, F o G",
            "image": image1,
            "value": 1
        },
    ];

    return (
        <>
            <h1 className='h2 fw-normal mb-5'>{stepTitle}</h1>
            <ButtonsStep stepElements={stepElements} stepTitle={stepTitle}></ButtonsStep>
        </>
    );
}

export default Step3;