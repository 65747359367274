import headerStyle from './header.module.css';
import logo from '../assets/logo.png';
import imgFaq from '../assets/faq.svg';
import { Link } from 'react-router-dom';

function Header() {
    return (
        <header className={headerStyle.header + ' py-3 px-5'}>
            <Link to={'/'} className={headerStyle.imgLogo}>
                <img src={logo} className={headerStyle.imgLogo} alt="logo" />
            </Link>
            <Link to={'/faq'} className={headerStyle.faq + ' btn fw-bold'}>
                <img src={imgFaq} className='img-fluid' alt="logo" />
                FAQ
            </Link>
        </header>
    );
}

export default Header;