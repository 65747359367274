import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Calcolatore from './pages/Calcolatore';
import { Provider } from 'react-redux';
import { createStore } from '@reduxjs/toolkit';
import calcolatoreReducer from './reducer/index';
import Faq from './pages/Faq';
import axios from "axios";

const store = createStore(
  calcolatoreReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ &&
  window.__REDUX_DEVTOOLS_EXTENSION__()
);

const URL_ARRAY = {
  'localhost:3000': 'http://triangolatore.locl/api/v1',
  'termoservice.adhc.it': 'https://crm.adhocweb.it/api/v1',
};

axios.defaults.baseURL = URL_ARRAY[window.location.host];
axios.defaults.headers.common = {
  'Accept-Language': localStorage.getItem('lang') || 'it',
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Suspense fallback='Loading...'>
      <BrowserRouter basename='/'>
        <Provider store={store}>
          <Routes>
            <Route path="/" element={<Calcolatore />}></Route>
            <Route path="/calcolatore" element={<Calcolatore />}></Route>
            <Route path="/faq" element={<Faq />}></Route>
          </Routes>
        </Provider>
      </BrowserRouter>
    </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
