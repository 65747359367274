import footerStyle from './footer.module.css';
import waIcon from '../assets/whatsapp.png';
import phoneIcon from '../assets/phone.svg';
import logoIcon from '../assets/logo-lungo.svg';
import fbIcon from '../assets/facebook.svg';
import igIcon from '../assets/instagram.svg';
import inIcon from '../assets/linkedin.svg';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <footer className="footer">
            <div className={footerStyle.bgOrange + ' d-flex py-3 px-5 flex-wrap'}>
                <div className='col-12 col-lg-8 mb-3 mb-lg-0'>
                    <p className='mb-0 fw-semibold'>Hai bisogno di assistenza? Contattaci: rispondiamo dal lunedì al venerdì (festività escluse) dalle 9:00 alle 18:00<br />Leggi l’informativa Privacy</p>
                </div>
                <div className='col-12 col-lg-4 d-flex align-items-center justify-content-end gap-3'>
                    <button type="button" className='btn btn-light d-flex align-items-center justify-content-center gap-3 fw-semibold flex-wrap flex-grow-1 flex-lg-grow-0'>
                        <img src={waIcon} className='img-fluid' />
                        WhatsApp
                    </button>
                    <button type="button" className='btn btn-light d-flex align-items-center justify-content-center gap-3 fw-semibold flex-wrap flex-grow-1 flex-lg-grow-0'>
                        <img src={phoneIcon} className='img-fluid' />
                        Chiamaci
                    </button>
                </div>
            </div>
            <div className={footerStyle.bgBlack + ' d-flex py-5 px-5 flex-wrap'}>
                <div className='col-12 col-md-6 col-lg-4 mb-3 mb-lg-0'>
                    <Link to={'/'}>
                        <img src={logoIcon} className='img-fluid p-3 p-md-0' />
                    </Link>
                </div>
                <div className='col-12 col-md-6 col-lg-4 mb-3 mb-lg-0'>
                    <ul>
                        <li>&copy; 2024 Termoservicegas srl, Via Salvemini 4/6, 63900, Fermo (FM)</li>
                        <li>P.Iva 02130360445</li>
                        <li>Privacy & Cookie Policy</li>
                        <li>Credits</li>
                    </ul>
                </div>
                <div className='col-12 col-lg-4'>
                    <div className='row'>
                        <div className='col-12 col-md-6 mb-3 mb-md-0'>
                            <ul>
                                <li>LA NOSTRA OFFERTA</li>
                                <li>PERCH&Eacute; TERMOSERVICEGAS</li>
                                <li>COSTI E INCENTIVI STATALI</li>
                                <li>CHI SIAMO</li>
                                <li>CONTATTI</li>
                            </ul>
                        </div>
                        <div className='col-12 col-md-6'>
                            <ul className='d-flex gap-4 justify-content-between align-items-center'>
                                <li><img src={fbIcon} className='img-fluid' /></li>
                                <li><img src={igIcon} className='img-fluid' /></li>
                                <li><img src={inIcon} className='img-fluid' /></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer >
    );
}

export default Footer;