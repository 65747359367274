export const PARAMS_COST_KWH = 0.146508;
export const PARAMS_KWH_WEIGHT = 1 / 0.146508;
export const PARAMS_COST_SMC = 0.6;
export const PARAMS_SMC_WEIGHT = 10;

export const PARAMS_MQ = {
    1: 90,
    2: 130,
    3: 200,
    4: 250,
    5: 300,
}

export const PARAMS_MQ_MULTIPLIER = {
    1: 1.11,
    2: 1.01,
    3: 0.81,
    4: 0.70,
    5: 0.67,
}

export const PARAMS_HOUSE_TYPOLOGY_HOT = {
    1: 1.00,
    2: 0.95,
    3: 0.90,
}

export const PARAMS_HOUSE_TYPOLOGY_COLD = {
    1: 1.10,
    2: 1.05,
    3: 1.00,
}

export const PARAMS_INSULATION_TYPOLOGY_HOT = {
    1: 200,
    2: 130,
    3: 30,
}

export const PARAMS_INSULATION_TYPOLOGY_COLD = {
    1: 1.50,
    2: 1.00,
    3: 0.60,
}

export const PARAMS_PHOTOVOLTAIC = {
    0: 0.90,
    1: 0.80,
}

export const PARAMS_KW_PHOTOVOLTAIC_HOT = {
    1: 0.85,
    2: 0.78,
    3: 0.72,
}

export const PARAMS_KW_PHOTOVOLTAIC_COLD = {
    1: 0.75,
    2: 0.63,
    3: 0.52,
}

export const PARAMS_HEATING_TYPOLOGY = {
    1: 1.20,
    2: 1.00,
    3: {
        1: 1.10,
        2: 1.00,
    },
}

export const PARAMS_BOILER_TYPOLOGY = {
    1: 1.00,
    2: 0.95,
}