import ButtonsStep from "./buttons-step";

import image1 from '../assets/water-heater.svg';
import image2 from '../assets/heater.svg';

function Step9() {
    const stepTitle = `Tipo di caldaia?`;

    const stepElements = [
        {
            "text": "Tradizionale",
            "image": image1,
            "value": 1
        },
        {
            "text": "Condensazione",
            "image": image2,
            "value": 2
        },
    ];

    return (
        <>
            <h1 className='h2 fw-normal mb-5 text-center'>{stepTitle}</h1>
            <ButtonsStep stepElements={stepElements} stepTitle={stepTitle}></ButtonsStep>
        </>
    );
}

export default Step9;