import ButtonsStep from "./buttons-step";

import image1 from '../assets/pellet-stove.svg';
import image2 from '../assets/heat-pump.svg';
import image3 from '../assets/water-heater.svg';
import image4 from '../assets/radiotor.svg';
import image5 from '../assets/floor.svg';

function Step7() {
    const stepTitle = `Tipo di riscaldamento?`;

    const subSteps = [
        {
            "text": "Radiatori",
            "image": image4,
            "value": "3-1"
        },
        {
            "text": "Pavimento",
            "image": image5,
            "value": "3-2"
        },
    ]

    const stepElements = [
        {
            "text": "Camino o stufa",
            "image": image1,
            "value": 1
        },
        {
            "text": "Pompa di calore",
            "image": image2,
            "value": 2
        },
        {
            "text": "Caldaia",
            "image": image3,
            "value": 3,
            "subSteps": {
                "title": "Che tipo di impianto?",
                "steps": subSteps
            }
        },
    ];

    return (
        <>
            <h1 className='h2 fw-normal mb-5 text-center'>{stepTitle}</h1>
            <ButtonsStep stepElements={stepElements} stepTitle={stepTitle}></ButtonsStep>
        </>
    );
}

export default Step7;