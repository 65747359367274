const initialState = {
    steps: []
};

const calcolatoreReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_STEP':
            return {
                ...state,
                steps: [
                    ...state.steps,
                    action.step
                ]
            }
        case 'REMOVE_STEP':
            return {
                ...state,
                steps: state.steps.filter((item, index) => index !== action.step)
            }
        case 'RESET_STEPS':
            return initialState;
        default:
            return state;
    }
}

export default calcolatoreReducer;