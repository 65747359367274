import Footer from '../layouts/footer';
import Header from '../layouts/header';
import { Wizard } from 'react-use-wizard';
import Step1 from '../layouts/step1';
import Step2 from '../layouts/step2';
import Step3 from '../layouts/step3';
// import Step4 from '../layouts/step4';
import Step5 from '../layouts/step5';
import Step6 from '../layouts/step6';
import Step7 from '../layouts/step7';
import Step8 from '../layouts/step8';
import Step9 from '../layouts/step9';
import './calcolatore.css';
import Step10 from '../layouts/step10';

function Calcolatore({ children }) {
  return (
    <div className="calcolatore">
      <Header />
      <div className="container py-5">
        <div className="py-5 d-flex align-items-center flex-column flex-grow-1">
          <Wizard>
            <Step1 />
            <Step2 />
            <Step3 />
            {/* <Step4 /> */}
            <Step5 />
            <Step6 />
            <Step7 />
            <Step8 />
            <Step9 />
            <Step10 />
          </Wizard>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Calcolatore;
