import ButtonsStep from "./buttons-step";

import image1 from '../assets/yes.svg';
import image2 from '../assets/no.svg';

function Step5() {
    const stepTitle = `Hai un impianto fotovoltaico?`;

    const subSteps = [
        {
            "text": "Si",
            "image": image1,
            "value": "0-1",
            "nextStep": 5
        },
        {
            "text": "No",
            "image": image2,
            "value": "0-2",
            "nextStep": 5
        },
    ]

    const stepElements = [
        {
            "text": "S&igrave;",
            "image": image1,
            "value": 1,
            "nextStep": 4
        },
        {
            "text": "No",
            "image": image2,
            "value": 0,
            "subSteps": {
                "title": "Ne valuti l'installazione?",
                "steps": subSteps
            }
        },
    ];

    return (
        <>
            <h1 className='h2 fw-normal mb-5 text-center'>{stepTitle}</h1>
            <ButtonsStep stepElements={stepElements} stepTitle={stepTitle}></ButtonsStep>
        </>
    );
}

export default Step5;