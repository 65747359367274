import ButtonsStep from "./buttons-step";

import image1 from '../assets/0-110.png';
import image2 from '../assets/111-150.png';
import image3 from '../assets/151-225.png';
import image4 from '../assets/226-285.png';
import image5 from '../assets/286.png';
import { Link } from "react-router-dom";

function Step1() {
    const stepTitle = `Quanto è grande la tua casa?`;

    const stepElements = [
        {
            "text": "0 - 110 mq",
            "image": image1,
            "value": 1
        },
        {
            "text": "111 - 150 mq",
            "image": image2,
            "value": 2
        },
        {
            "text": "151 - 225 mq",
            "image": image3,
            "value": 3
        },
        {
            "text": "226 - 285 mq",
            "image": image4,
            "value": 4
        },
        {
            "text": "286 mq +",
            "image": image5,
            "value": 5
        },
    ];

    return (
        <>
            <h1 className='h2 fw-normal mb-5 text-center'>{stepTitle}</h1>
            <div className="d-flex align-items-center justify-content-between gap-4">
                <ButtonsStep stepElements={stepElements} stepTitle={stepTitle}></ButtonsStep>
            </div>
        </>
    );
}

export default Step1;