import React, { useMemo, useState } from 'react';
import Loader from "../components/Loader";

export function useLoader(style = {}, initLoading = false, passedDelaySeconds = 0) {
    const [loading, setLoading] = useState(initLoading);

    const asJsx = useMemo(() => (loading ? <Loader style={style} /> : null), [loading]);
    const setStartLoading = () => setLoading(true);
    const setEndLoading = (delaySeconds = passedDelaySeconds) => {
        if (!delaySeconds) setLoading(false);
        else
            setTimeout(() => {
                setLoading(false);
            }, delaySeconds * 1000);
    };
    return {
        loading,
        asJsx,
        setStartLoading,
        setEndLoading,
        watchPromise(promise) {
            setStartLoading();
            promise.finally((_) => {
                setEndLoading();
            });
        },
    };
}