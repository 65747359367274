import LoremIpsum from 'react-lorem-ipsum';
import Footer from '../layouts/footer';
import Header from '../layouts/header';
import { Card, CardBody, CardHeader } from 'react-bootstrap';

function Faq({ children }) {
  return (
    <div className="calcolatore">
      <Header />
      <div className="container py-5">
        <div className="py-5 d-flex align-items-center flex-column flex-grow-1">
          <Card className='mb-3'>
            <CardHeader className='text-center'>
              Lorem ipsum odor amet?
            </CardHeader>
            <CardBody>
              <LoremIpsum p={1}></LoremIpsum>
            </CardBody>
          </Card>
          <Card>
            <CardHeader className='text-center'>
              Lorem ipsum odor amet?
            </CardHeader>
            <CardBody>
              <LoremIpsum p={2} avgSentencesPerParagraph={8} avgWordsPerSentence={8}></LoremIpsum>
            </CardBody>
          </Card>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Faq;
