import React from 'react';

const Loader = ({ style = {} }) => {
    return (
        <>
            <div className="spinner-border" style={{ color: '#DD9932' }} role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
            <p className='mt-3'>Stiamo calcolando i tuoi risparmi</p>
        </>
    );
};

export default Loader;
