import { useContext, useEffect, useRef, useState } from 'react';
import { ReactReduxContext, useDispatch } from 'react-redux';
import { useWizard } from 'react-use-wizard';
import { ADD_STEP, REMOVE_STEP, RESET_STEPS } from '../constants/ActionTypes';
import { motion } from "framer-motion";
import backarrowIcon from '../assets/backarrow.svg';
import { Link, useLocation } from 'react-router-dom';

function ButtonsStep({ stepElements, stepTitle, pQuestion = false, pAnswer = false, backButton = true }) {
    const { previousStep, goToStep, isFirstStep, activeStep } = useWizard();
    const [subStepsActive, setSubStepsActive] = useState(false);
    const dispatch = useDispatch();
    const { store } = useContext(ReactReduxContext);
    const location = useLocation();
    const myRef = useRef(null);
    const [parentQuestion, setParentQuestion] = useState(false);
    const [parentAnswer, setParentAnswer] = useState(false);

    useEffect(() => {
        if (location.pathname === '/') {
            dispatch({
                type: RESET_STEPS,
            });

            backToTop();

            goToStep(0);
        }
    }, [location]);

    useEffect(() => {
        if (subStepsActive) executeScroll();
    });

    const container = {
        hidden: { opacity: 1, scale: 0 },
        visible: {
            opacity: 1,
            scale: 1,
            transition: {
                delayChildren: 0.3,
                staggerChildren: 0.2
            }
        }
    };

    const item = {
        hidden: { y: 20, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1
        }
    };

    const backToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour 
               in place of 'smooth' */
        });
    }

    const executeScroll = () => myRef.current.scrollIntoView()

    const goBack = () => {
        var hasStepKW = false;

        store.getState().steps.forEach(element => {
            if (element.nStep === 4) hasStepKW = true;
        });

        if (activeStep > 4 && !hasStepKW) {
            dispatch({
                type: REMOVE_STEP,
                step: activeStep - 2,
            });
        } else {
            dispatch({
                type: REMOVE_STEP,
                step: activeStep - 1,
            });
        }

        backToTop();

        if (activeStep === 5 && !hasStepKW) goToStep(3);
        else previousStep();
    };

    const goTo = (value, textAnswer, index) => {
        var step = { nStep: activeStep, value: value, question: stepTitle, answer: textAnswer };
        if (pQuestion && pAnswer) step["parent"] = { question: pQuestion, answer: pAnswer };

        dispatch({
            type: ADD_STEP,
            step: step,
        });

        backToTop();

        goToStep(index);
    };

    const GoBackButton = () => {
        return (
            <button
                onClick={
                    (e) => { goBack() }
                }
                className="btn border-0 d-flex align-items-center gap-3"
            >
                <img src={backarrowIcon} className='img-fluid' />
                Indietro
            </button>
        );
    }

    const Button = ({ element }) => {
        return (
            <>
                <motion.button
                    variants={item}
                    onClick={
                        (e) => {
                            if (!element.subSteps) {
                                if (element.nextStep) goTo(e.currentTarget.value, e.currentTarget.attributes['data-answer'].value, element.nextStep);
                                else goTo(e.currentTarget.value, e.currentTarget.attributes['data-answer'].value, activeStep + 1);
                            } else {
                                setParentQuestion(stepTitle);
                                setParentAnswer(e.currentTarget.attributes['data-answer'].value);
                                setSubStepsActive(true);
                            }
                        }
                    }
                    className="btn d-flex align-items-center justify-content-between step-button"
                    style={{ borderRadius: 20 + 'px' }}
                    value={element.value}
                    data-answer={element.text}
                >
                    <div className="d-flex align-items-center w-100 flex-column gap-2">
                        {element.image ? (
                            <img src={element.image} style={{ marginBottom: 10 + 'px', maxWidth: 100 + "px", width: 100 + '%', maxHeight: 100 + 'px', height: "auto" }} />
                        ) : null}
                        <p className='fw-500 mb-0' dangerouslySetInnerHTML={{ __html: element.text }}></p>
                    </div>
                </motion.button>
            </>
        )
    }

    return (
        <>
            <motion.div
                className="d-flex align-items-center justify-content-center gap-4 mb-4 flex-wrap"
                variants={container}
                initial="hidden"
                animate="visible"
            >
                {stepElements.map(element => {
                    return <>
                        {activeStep === 0 ? (
                            <Link to={'/calcolatore'} className='text-decoration-none'>
                                <Button element={element} />
                            </Link>
                        ) : <Button element={element} />}
                    </>
                })}
                {stepElements.map(element => {
                    return <>
                        {element.subSteps ? (
                            <div ref={myRef} className={"w-100 collapse" + (subStepsActive ? " show" : "")}>
                                <h1 className='h2 fw-normal mb-5 mt-3 text-center'>{element.subSteps.title}</h1>
                                <ButtonsStep stepElements={element.subSteps.steps} stepTitle={element.subSteps.title} pQuestion={parentQuestion} pAnswer={parentAnswer} backButton={false} />
                            </div>
                        ) : null}
                    </>
                })}
            </motion.div>
            {!isFirstStep && backButton ? (
                <>
                    {activeStep === 1 ? (
                        <Link to={'/'} className='text-decoration-none'>
                            <GoBackButton />
                        </Link>
                    ) :
                        <GoBackButton />
                    }
                </>
            ) : null}
        </>
    );
}

export default ButtonsStep;