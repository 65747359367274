import ButtonsStep from "./buttons-step";

import image1 from '../assets/power-3kw.svg';
import image2 from '../assets/power-6kw.svg';
import image3 from '../assets/power-9kw.svg';

function Step6() {
    const stepTitle = `Qual è la potenza del tuo impianto?`;

    const stepElements = [
        {
            "text": "3kW",
            "image": image1,
            "value": 1
        },
        {
            "text": "6kW",
            "image": image2,
            "value": 2
        },
        {
            "text": "9kW",
            "image": image3,
            "value": 2
        },
    ];

    return (
        <>
            <h1 className='h2 fw-normal mb-5 text-center'>{stepTitle}</h1>
            <ButtonsStep stepElements={stepElements} stepTitle={stepTitle}></ButtonsStep>
        </>
    );
}

export default Step6;